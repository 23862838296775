import * as React from 'react'
import { GraphqlResults } from '../support-types'
import { Container, createStyles, Theme, Typography } from '@material-ui/core'
import BookGrid from '../components/book/BookGrid'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    center: {
      textAlign: 'center',
    },
    bookGrid: {
      width: '100%',
      margin: 'auto',
      paddingTop: 20,
      display: 'flex',
    },
    bookSection: {
      marginTop: 50,
    },
  })
)

const BooksPage = ({ data, pageContext }: GraphqlResults) => {
  const classes = useStyles()

  const books = data.books.nodes

  return (
    <>
      <SEO title="Books" />

      <Container className={classes.bookGrid}>
        <BookGrid books={books} />
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    books: allSanityBook {
      nodes {
        _id
        slug {
          current
        }
        title
        shortDescription
        price
        frontCover {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              width: 400
              height: 400
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`

export default BooksPage
