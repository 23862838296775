import React from 'react'
import { Grid } from '@material-ui/core'
import BookCard from './BookCard'
import Book from '../../model/book'

interface BookGridProps {
  books: Book[]
}

const BookGrid = ({ books }: BookGridProps) => {
  const bookItems = books.map((book) => (
        <Grid item key={book.isbn || book.title} zeroMinWidth sm={12} md={6} lg={4}>
          <BookCard book={book} />
        </Grid>
    ))

  return (
    <Grid
      container
      spacing={4}
      title={'Books'}
      justifyContent="space-evenly"
      alignContent="space-between"
    >
      {bookItems}
    </Grid>
  )
}

export default BookGrid
