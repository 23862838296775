import React, { useState } from 'react'
import Book from '../../model/book'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'

interface BookCardProps {
  book: Book
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    minWidth: theme.breakpoints.values.xs,
    maxWidth: theme.breakpoints.values.md,
    height: '100%',
    marginLeft: 10,
    marginRight: 10,
    position: 'relative',
  },
  cardContent: {
    color: 'black',
    marginBottom: 20,
    textAlign: 'center',
  },
  cardImage: {
    objectFit: 'contain',
    flex: 1,
    textAlign: 'center',
  },
  cardAction: {
    position: 'absolute',
    bottom: -2,
    right: 2,
  },
  image: {
    borderTopWidth: '5px',
    borderTopStyle: 'solid',
    borderTopColor: 'rgba(0,0,0,0)',
    borderTop: '3px solid rgba(0,0,0,0)',
    transition: theme.transitions.create(['borderTopColor']),
    '&:hover': {
      borderTopColor: 'red',
    },
  },
  title: {
    fontWeight: 'bold',
  },
}))

const BookCard = ({ book }: BookCardProps) => {
  const classes = useStyles()

  const showBook = (slug: string) => navigate(`/books/${slug}`)

  return (
    <Card className={classes.card} onClick={(_) => showBook(book.slug.current)}>
      <CardMedia className={classes.cardImage}>
        <GatsbyImage
          image={book.frontCover.asset.gatsbyImageData}
          alt={book.title}
          className={classes.image}
        />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6">{book.shortDescription}</Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Button
          size="small"
          color="primary"
          variant='contained'
          onClick={(_) => showBook(book.slug.current)}
          >
          <Typography variant="button">Learn More...</Typography>
        </Button>
      </CardActions>
    </Card>
  )
}

export default BookCard
